import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { Button } from '@/components/ui/button';
import { Switch } from '@/components/ui/switch';
import { ArrowUpDown, Trash2 } from 'lucide-react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { useMutation } from '@apollo/client';
import {
  RemoveDepartmentPromptDocument,
  ToggleDepartmentPromptDocument,
} from '@@graphql';
import { GroupData } from '@/pages/settings/group-dialog/types.ts';
import UpdatePromptDialog from '@/pages/settings/group-dialog/update-prompt-dialog.tsx';

export const groupColumns = (
  departmentId: string,
  toast: any,
  refetchPrompts: () => void,
): ColumnDef<GroupData>[] => [
  {
    accessorKey: 'keywords',
    header: ({ column }) => (
      <div
        className="flex items-center cursor-pointer"
        onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
      >
        關鍵字
        <ArrowUpDown className="w-4 h-4 ml-2" />
      </div>
    ),
    cell: ({ row }) => row.getValue('keywords'),
  },
  {
    accessorKey: 'enable',
    header: ({ column }) => (
      <div
        className="flex items-center cursor-pointer"
        onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
      >
        是否啟用
        <ArrowUpDown className="w-4 h-4 ml-2" />
      </div>
    ),
    cell: ({ row }) => (
      <EnableSwitch
        row={row}
        departmentId={departmentId}
        toast={toast}
        refetchPrompts={refetchPrompts}
      />
    ),
  },
  {
    id: 'edit',
    header: '編輯',
    cell: ({ row }) => (
      <EditKeywordsButton
        departmentId={departmentId}
        departmentPromptId={row.original.id}
        refetchPrompts={refetchPrompts}
      />
    ),
  },
  {
    id: 'delete',
    header: '刪除',
    cell: ({ row }) => (
      <DeletePromptButton
        row={row}
        departmentId={departmentId}
        toast={toast}
        refetchPrompts={refetchPrompts}
      />
    ),
  },
];

const EnableSwitch: React.FC<{
  row: any;
  departmentId: string;
  toast: any;
  refetchPrompts: (departmentId: string) => void;
}> = ({ row, departmentId, toast, refetchPrompts }) => {
  const [togglePromptMutation] = useMutation(ToggleDepartmentPromptDocument);

  const togglePrompt = async (isChecked: boolean) => {
    await togglePromptMutation({
      variables: {
        departmentPromptId: row.original.id,
        enabled: isChecked,
      },
    });
    toast({
      title: isChecked ? '已啟用成功' : '已取消啟用成功',
    });
    refetchPrompts(departmentId);
  };

  return (
    <Switch
      id="enable"
      defaultChecked={row.getValue('enable')}
      onCheckedChange={togglePrompt}
    />
  );
};

interface EditKeywordsButtonProps {
  departmentId: string;
  departmentPromptId: string;
  refetchPrompts: () => void;
}

const EditKeywordsButton = ({
  departmentId,
  refetchPrompts,
  departmentPromptId,
}: EditKeywordsButtonProps) => {
  return (
    <UpdatePromptDialog
      departmentId={departmentId}
      departmentPromptId={departmentPromptId}
      onPromptCreated={refetchPrompts}
    />
  );
};

interface DeletePromptButtonProps {
  row: any;
  departmentId: string;
  toast: any;
  refetchPrompts: (departmentId: string) => void;
}

const DeletePromptButton = ({
  row,
  departmentId,
  toast,
  refetchPrompts,
}: DeletePromptButtonProps) => {
  const [removePromptMutation] = useMutation(RemoveDepartmentPromptDocument);

  const removePrompt = async () => {
    await removePromptMutation({
      variables: {
        departmentPromptId: row.original.id,
      },
    });
    toast({ title: '刪除文件分類規則成功' });
    refetchPrompts(departmentId);
  };

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button
          className="size-8 text-error hover:text-error"
          variant="ghost"
          size="icon"
        >
          <Trash2 size={20} strokeWidth={1.5} />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>刪除分類規則</AlertDialogTitle>
          <AlertDialogDescription>{`確定要刪除『${row.original.keywords}』?`}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>取消</AlertDialogCancel>
          <AlertDialogAction onClick={removePrompt}>確定</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
