import { FormEvent, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  KeywordsDocument,
  UpdateDepartmentKeywordDocument,
} from '@@graphql';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
  DialogClose,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Pencil } from 'lucide-react';
import { useErrorHandler } from '@/hooks/useErrorHandler';
import { createPromptSchema } from '@/zodSchema/create-prompt';
import { useToast } from '@/components/ui/use-toast.ts';
import { Label } from '@/components/ui/label.tsx';
import InputTags from '@/components/InputTags.tsx';

interface UpdatePromptDialogProps {
  departmentId: string;
  departmentPromptId: string;
  onPromptCreated: (departmentId: string) => void;
}

const UpdatePromptDialog = ({
  departmentId,
  departmentPromptId,
  onPromptCreated,
}: UpdatePromptDialogProps) => {
  const { toast } = useToast();
  const [isUpdateKeywordsOpen, setIsUpdateKeywordsOpen] = useState(false);
  const { errors, handleError, resetErrors } = useErrorHandler({ keywords: '' });
  const [keywords, setKeywords] = useState<string[]>([]);
  const [initialKeywords, setInitialKeywords] = useState<string[]>([]);

  const [updateDepartmentKeywordMutation] = useMutation(
    UpdateDepartmentKeywordDocument,
    {
      onCompleted: () => onPromptCreated(departmentId),
    },
  );
  const [getKeywords] = useLazyQuery(KeywordsDocument, {
    fetchPolicy: 'network-only',
  });

  const handleOpenDialog = () => {
    setIsUpdateKeywordsOpen(true);
  };

  const handleCloseDialog = () => {
    setIsUpdateKeywordsOpen(false);
    resetErrors();
    setKeywords([]);
    setInitialKeywords([]);
  };

  const onFormSubmitUpdateKeywords = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      resetErrors();
      createPromptSchema.parse({ keywords });
      await updateDepartmentKeywordMutation({
        variables: { departmentPromptId, keywords },
      });
      toast({ title: '編輯文件分類規則成功', duration: 1500 });
      handleCloseDialog();
    } catch (err: unknown) {
      handleError(err);
    }
  };

  const handleTagsChange = (newTags: string[]) => {
    setKeywords(newTags);
    setInitialKeywords(newTags);
  };

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      resetErrors();
      setKeywords([]);
      setInitialKeywords([]);
    }
    setIsUpdateKeywordsOpen(open);
  };

  return (
    <Dialog open={isUpdateKeywordsOpen} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>
        <Button
          className="gap-1 font-normal text-primary "
          variant="ghost"
          onClick={handleOpenDialog}
        >
          <Pencil size={20} strokeWidth={1.5} />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <form onSubmit={onFormSubmitUpdateKeywords}>
          <DialogHeader>
            <DialogTitle>編輯文件分類規則</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="keyword" className="text-right font-normal">
                關鍵字
              </Label>
              <InputTags
                key={initialKeywords.join(',')}
                keywordsQuery={getKeywords}
                defaultTags={keywords}
                maxTags={4}
                onTagsChange={handleTagsChange}
              />
              {errors.keywords && <div className="text-red-500">{errors.keywords}</div>}
            </div>
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button variant="outline" type="button">
                取消
              </Button>
            </DialogClose>
            <Button type="submit">儲存</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default UpdatePromptDialog;
